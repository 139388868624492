<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap mb-2">
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">ประเภทแบบทดสอบ</label>

        <v-select
          class="mx-2"
          id="lesson_start_hrs"
          placeholder="เลือก ข้อสอบ หรือ แบบทดสอบ"
          :options="testTypes"
          v-model="form.test_type"
        ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณาเลือกประเภทแบบทดสอบ
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-8 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">ชื่อแบบทดสอบ</label>

        <b-form-input id="lastName" type="text" v-model="form.test_name"></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่ออาจารย์ผู้สอน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">สถานะ</label>

        <v-select
          class="mx-2"
          id="lesson_start_hrs"
          placeholder="เลือก"
          :options="status"
          v-model="form.test_status"
        ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณาเลือกประเภทแบบทดสอบ
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">รายละเอียดข้อสอบ</label>
<b-textarea
            name="note"
            id="note"
           v-model="form.description"
          >
          </b-textarea>
        <!-- <div
          contenteditable="true"
          class="border border-medium rounded pre-line p-3"
          
        ></div> -->

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกรายละเอียดข้อสอบ
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <div class="d-flex flex-row align-items-baseline">
          <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
          <h4 class="font-weight-bold ft-s-18">ช่วงเวลาการทำแบบทดสอบ</h4>
        </div>
      </div>
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">เริ่ม</label>

        <datepicker
          ref="datepicker"
          id="lessonDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          v-model="form.test_start_date"
          @selected="onStartDateChanged($event)"
        ></datepicker>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณาเลือกวันที่เริ่มเปิด
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">สิ้นสุด</label>

        <datepicker
          ref="datepicker"
          id="lessonDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          v-model="form.test_end_date"
          @selected="onEndDateChanged($event)"
        ></datepicker>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณาเลือกประเภทแบบทดสอบ
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">กำหนดเวลาการทำ (นาที)</label>

        <v-select
          class="mx-2"
          id="lesson_start_hrs"
          placeholder="เลือก"
          :options="testTimes"
          v-model="form.test_time"
        ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณาเลือกประเภทแบบทดสอบ
        </b-form-invalid-feedback>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap">
      <b-col cols="12">
        <test-question
          ref=""
          :invalid="invalid"
          :validation="validation.form.questions"
          :disabled="disabled"
          v-model="form.questions"
        ></test-question>
      </b-col>
    </div>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import { Auth } from "../../models";
import TestQuestion from "./TestQuestion";

import { th } from "vuejs-datepicker/dist/locale";
// import CarouselTeachingImage from "../carousel/TeachingImage";
// import Datepicker from "vuejs-datepicker";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import Datepicker from "vuejs-datepicker";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    "v-select": vSelect,
    // quillEditor,
    TestQuestion,
    Datepicker,
  },

  data() {
    return {
      testTimes: [
        "ไม่กำหนดเวลา",
        "30",
        "60",
        "90",
        "120",
      ],
      th: th,
      testTypes: ["ข้อสอบ", "แบบทดสอบ"],
      status: ["ใช้งาน", "ปิดการใช้งาน"],
      form: {
        id: this.editData.id || null,
        test_type: "",
        test_name: "",
        test_status: "",
        test_start_date: "",
        test_end_date: "",
        test_time: "ไม่กำหนดเวลา",
        description: this.editData.description || "",
        questions:
          this.editData.questions ||
          [],
        // general_questions: [
        //   {
        //     question_no: 1,
        //     question_type: "text",
        //     question_title: "",
        //   },
        //   {
        //     question_no: 2,
        //     question_type: "choice",
        //     question_title: "",
        //     question_choices: [
        //       {
        //         question_choice_no: 1,
        //         question_choice_title: "",
        //       },
        //       {
        //         question_choice_no: 2,
        //         question_choice_title: "",
        //       },
        //     ],
        //   },
        //   {
        //     question_no: 3,
        //     question_type: "rating",
        //     question_title: "",
        //   },
        // ],
      },

      surveyTypes: [
        "แบบประเมินการเข้าสอน",
        "แบบประเมินผล(ครูประจำชั้น)",
        "แบบประเมินผล(นักเรียน)",
        "แบบประเมินผล(ผู้ปกครอง)",
      ],

      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        placeholder: "รายละเอียด",
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    formatBuddhistBirthDate,
    syncData(v) {
      this.$emit("change", v);
    },
    onStartDateChanged(date) {
      if (date) {
        const dObj = new Date(date.getTime());
        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(this.form.test_start_date, "date", dObj.toISOString());
      }
    },
    onEndDateChanged(date) {
      if (date) {
        const dObj = new Date(date.getTime());
        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(this.form.test_end_date, "date", dObj.toISOString());
      }
    },
  },
};
</script>
