var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',[_c('label',{staticClass:"font-weight-bold ft-s-18 mr-2"},[_vm._v("คำถามข้อที่ "+_vm._s(_vm.questionNo))]),_c('span',[_vm._v("ประเภท: "+_vm._s(_vm.getQuestionTypeName(_vm.question.question_type)))])]),_c('b-col',{staticClass:"text-right pt-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('font-awesome-icon',{staticClass:"mx-auto",attrs:{"icon":"window-close"}})],1)])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"font-weight-bold ft-s-14",attrs:{"for":("question-" + _vm.questionIndex + "-title")}},[_vm._v("คำถาม")]),_c('b-textarea',{staticClass:"mb-2",attrs:{"id":("question-" + _vm.questionIndex + "-title"),"state":_vm.invalid
          ? _vm.validation.$each[_vm.questionIndex].question_title.required
          : null,"rows":"1","max-rows":"4"},model:{value:(_vm.question.question_title),callback:function ($$v) {_vm.$set(_vm.question, "question_title", $$v)},expression:"question.question_title"}}),_c('b-form-invalid-feedback',{attrs:{"id":("question-" + _vm.questionIndex + "-title-feedback")}},[_vm._v(" กรุณากรอกคำถาม ")])],1),(_vm.question.question_type === 'choice')?_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"font-weight-bold ft-s-14 mt-2",attrs:{"for":("question-" + _vm.questionIndex + "--choice-title")}},[_vm._v("เพิ่มตัวเลือก")]),_vm._l((_vm.question.question_choices),function(questionChoice,questionChoiceIndex){return [_c('b-form-row',{key:("question-" + _vm.questionIndex + "--choice-" + questionChoiceIndex)},[_c('b-col',[_c('b-input-group',{staticClass:"mt-3 input-group-seamless align-items-center"},[_c('b-form-input',{attrs:{"id":("question-" + _vm.questionIndex + "--choice-" + questionChoiceIndex + "-title"),"name":("question-" + _vm.questionIndex + "--choice-" + questionChoiceIndex + "-title"),"state":_vm.invalid
                  ? _vm.validation.$each[_vm.questionIndex].question_choices.$each[
                      questionChoiceIndex
                    ].question_choice_title.required
                  : null},model:{value:(
                _vm.question.question_choices[questionChoiceIndex]
                  .question_choice_title
              ),callback:function ($$v) {_vm.$set(_vm.question.question_choices[questionChoiceIndex]
                  , "question_choice_title", $$v)},expression:"\n                question.question_choices[questionChoiceIndex]\n                  .question_choice_title\n              "}}),_c('b-form-checkbox',{staticClass:"ml-3",on:{"change":function($event){return _vm.clickCorrect(questionChoiceIndex)}},model:{value:(_vm.question.question_choices[questionChoiceIndex].question_choice_corect),callback:function ($$v) {_vm.$set(_vm.question.question_choices[questionChoiceIndex], "question_choice_corect", $$v)},expression:"question.question_choices[questionChoiceIndex].question_choice_corect"}},[_vm._v(" ข้อถูก ")]),_c('a',{staticClass:"ml-3",attrs:{"href":"javascript:void(0)"}},[_c('font-awesome-icon',{attrs:{"icon":"window-close"},on:{"click":function($event){return _vm.removeChoice(questionChoiceIndex)}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":("question-" + _vm.questionIndex + "--choice-" + questionChoiceIndex + "-title-feedback")}},[_vm._v(" กรุณากรอกตัวเลือก ")])],1)],1)],1)]}),_c('b-form-row',[_c('b-col',[_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addChoice}},[_vm._v("เพิ่มคำตอบ")])],1)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }