<template>
  <page-content>
    <page-title heading="การจัดการแบบทดสอบ/ข้อสอบ">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate">เพิ่มแบบทดสอบ/ข้อสอบ</b-button>
      </template>
    </page-title>

    <!-- <b-row>
      <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อโรงเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row> -->

    <tests-table
      ref="table"
      class="mt-4"
      :fields="fields"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-tests-form
      :edit-data.sync="editData"
      v-model="showModalSurvey"
      @create:success="onCreateSuccess"
    ></create-or-update-tests-form>

    <tests-delete
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></tests-delete>
  </page-content>
</template>

<script>
import { groupBy, orderBy } from "lodash";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateTestsForm from "../../components/modal/CreateOrUpdateTestsForm";
import TestsTable from "../../components/table/Tests";
// import FilterProvince from "../../components/form/FilterProvince";
import TestsDelete from "../../components/modal/TestsDelete";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateTestsForm,
    TestsTable,
    TestsDelete,
  },

  data() {
    return {
      keyword: "",
      editData: {},
      deleteData: {},
      showModalSurvey: false,
      showModalDelete: false,
      fields: [
        { key: "test_name", label: "ชื่อข้อสอบ" },
        // { key: "survey_type", label: "ประเภท" },
        {
          key: "test_start_date",
          label: "วันที่เริ่ม",
        },
        {
          key: "test_end_date",
          label: "วันที่สิ้นสุด",
        },
        // {
        //   key: "createdAt",
        //   label: "วันที่สร้าง",
        // },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    onCreate() {
      this.showModalSurvey = true;
    },

    async onEdit(item) {
      let { questions = [], survey_topics = [] } = item;

      let editedQuestions = [];

      let genrealQuestions = questions.filter(
        (question) => question.surveyTopicId === null
      );

      let topicQuestions = questions.filter(
        (question) => question.surveyTopicId !== null
      );

      if (genrealQuestions && genrealQuestions.length) {
        editedQuestions = [...editedQuestions, ...genrealQuestions];
      }

      if (topicQuestions && topicQuestions.length) {
        topicQuestions = groupBy(topicQuestions, "surveyTopicId");
      }

      if (survey_topics && survey_topics.length) {
        survey_topics = survey_topics.map((surveyTopic) => {
          let questions = [];

          if (topicQuestions && topicQuestions[surveyTopic.id]) {
            questions = topicQuestions[surveyTopic.id];
          }

          return {
            ...surveyTopic,
            question_type: "topic",
            question_no: surveyTopic.survey_topic_no,
            questions,
          };
        });

        editedQuestions = [...editedQuestions, ...survey_topics];
      }

      editedQuestions = orderBy(editedQuestions, "question_no");

      // editedQuestions = editedQuestions.map(
      //   editedQuestion => {
      //     let formData = {};

      //     let { question_type } = editedQuestion;

      //     if (question_type === "topic") {
      //       this.$set(formData, "survey_topic_title", editedQuestion.survey_topic_title);
      //       this.$set(formData, "survey_topic_title", editedQuestion.survey_topic_title);
      //     } else {

      //     }
      //   }
      // );

      // console.log(
      //   editedQuestions,
      //   genrealQuestions,
      //   topicQuestions,
      //   survey_topics
      // );
      this.editData = {
        id: item.id,
        survey_title: item.survey_title,
        survey_type: item.survey_type,
        description: item.description,
        questions: editedQuestions,
      };

      // console.log(this.editData);

      this.showModalSurvey = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
