<template>
  <b-col class="px-0">
    <b-row align-h="between" class="mt-4">
      <b-col class="pl-1">
        <div class="d-flex flex-row align-items-baseline">
          <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
          <h4 class="font-weight-bold ft-s-18">เพิ่มคำถาม</h4>
        </div>
      </b-col>

      <b-col class="text-right pr-2">
        <b-dropdown
          size="sm"
          right
          text="เพิ่ม"
          variant="primary"
          :disabled="disabled"
        >
          <b-dropdown-item @click="addTopic">หัวข้อใหญ่</b-dropdown-item>
          <!-- <b-dropdown-item @click="addGeneralQuestion('text')"
            >คำถามประเภทพิมพ์ตอบ</b-dropdown-item
          > -->
          <b-dropdown-item @click="addGeneralQuestion('choice')"
            >คำถามประเภทเลือกตอบ</b-dropdown-item
          >
          <!-- <b-dropdown-item @click="addGeneralQuestion('rating')"
            >คำถามประเภทให้คะแนน</b-dropdown-item
          > -->
        </b-dropdown>
      </b-col>
    </b-row>

    <template v-for="(question, questionIndex) in questions">
      <b-card
        v-if="question.question_type == 'topic'"
        :key="`survey-topic-${questionIndex}`"
        :ref="`survey-topic-${questionIndex}`"
        class="mt-4"
      >
        <b-form-row align-h="between">
          <b-col>
            <label class="font-weight-bold ft-s-18"
              >หัวข้อใหญ่ที่ {{ questionIndex + 1 }}</label
            >
          </b-col>

          <b-col class="text-right">
            <b-dropdown
              size="sm"
              right
              :text="`เพิ่มคำถาม`"
              variant="primary"
              class="mr-4"
              :disabled="disabled"
            >
              <!-- <b-dropdown-item @click="addTopicQuestion('text', questionIndex)"
                >คำถามประเภทพิมพ์ตอบ</b-dropdown-item
              > -->
              <b-dropdown-item
                @click="addTopicQuestion('choice', questionIndex)"
                >คำถามประเภทเลือกตอบ</b-dropdown-item
              >
              <!-- <b-dropdown-item
                @click="addTopicQuestion('rating', questionIndex)"
                >คำถามประเภทให้คะแนน</b-dropdown-item
              > -->
            </b-dropdown>

            <a href="javascript:void(0)" @click="removeTopic(questionIndex)">
              <font-awesome-icon class="mx-auto" icon="window-close" />
            </a>
          </b-col>
        </b-form-row>

        <b-form-group
          :id="`survey-topic-${questionIndex}`"
          label="ชื่อหัวข้อ"
          :label-for="`surveyTopicTitle-${questionIndex}`"
        >
          <b-form-input
            :id="`surveyTopicTitle-${questionIndex}`"
            :state="
              invalid
                ? validation.$each[questionIndex].survey_topic_title.required
                : null
            "
            v-model="questions[questionIndex].survey_topic_title"
            type="text"
            required
          ></b-form-input>

          <b-form-invalid-feedback
            :id="`surveyTopicTitle-${questionIndex}-feedback`"
          >
            กรุณากรอกชื่อหัวข้อ
          </b-form-invalid-feedback>
        </b-form-group>

        <b-alert
          :show="
            invalid
              ? !validation.$each[questionIndex].questions.required
              : false
          "
          variant="danger"
          >กรุณาเพิ่มคำถามอย่างน้อย 1 คำถาม</b-alert
        >

        <test-question-item
          v-for="(topicQuestion, topicQuestionIndex) in question.questions"
          :key="`topicQuestion-${questionIndex}--${topicQuestionIndex}`"
          :ref="`topicQuestion-${questionIndex}--${topicQuestionIndex}`"
          :invalid="invalid"
          :validation="validation.$each[questionIndex].questions"
          :topicIndex="questionIndex"
          :questionIndex="topicQuestionIndex"
          v-model="questions[questionIndex].questions[topicQuestionIndex]"
          class="mt-4"
          @remove="removeTopicQuestion(questionIndex, topicQuestionIndex)"
        ></test-question-item>
      </b-card>

      <b-card
        v-else
        :key="`question-${questionIndex}`"
        :ref="`question-${questionIndex}`"
        class="mt-4"
      >
        <test-question-item
          :questionIndex="questionIndex"
          :invalid="invalid"
          :validation="validation"
          v-model="questions[questionIndex]"
          @remove="removeQuestion"
        >
        </test-question-item>
      </b-card>

      <!-- <b-form-row
        :key="`question-${questionIndex}`"
        :ref="`question-${questionIndex}`"
        class="mb-2"
      >
        <b-col cols="12" class="mt-4">
          <b-form-row align-h="between" align-v="center">
            <b-col>
              <label class="font-weight-bold ft-s-18 mr-2"
                >คำถามข้อที่ {{ questionIndex + 1 }}</label
              >

              <span
                >ประเภท: {{ getQuestionTypeName(question.question_type) }}</span
              >
            </b-col>

            <b-col class="text-right pt-2">
              <a
                href="javascript:void(0)"
                @click="removeQuestion(questionIndex)"
              >
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-col>
          </b-form-row>
        </b-col>

        <b-col cols="12">
          <label
            class="font-weight-bold ft-s-14"
            :for="`question-${questionIndex}-title`"
            >คำถาม</label
          >

          <b-textarea
            :id="`question-${questionIndex}-title`"
            :state="
              invalid
                ? validation.$each[questionIndex].question_title.required
                : null
            "
            v-model="questions[questionIndex].question_title"
            class="mb-2"
            rows="1"
            max-rows="4"
          >
          </b-textarea>

          <b-form-invalid-feedback
            :id="`question-${questionIndex}-title-feedback`"
          >
            กรุณากรอกคำถาม
          </b-form-invalid-feedback>
        </b-col>

        <b-col v-if="question.question_type === 'choice'" cols="12">
          <label
            class="font-weight-bold ft-s-14 mt-2"
            :for="`question-${questionIndex}--choice-title`"
            >เพิ่มตัวเลือก</label
          >

          <template
            v-for="(questionChoice, questionChoiceIndex) in questions[
              questionIndex
            ].question_choices"
          >
            <b-form-row
              :key="`question-${questionIndex}--choice-${questionChoiceIndex}`"
            >
              <b-col>
                <b-input-group
                  class="mt-3 input-group-seamless align-items-center"
                >
                  <b-form-input
                    :id="
                      `question-${questionIndex}--choice-${questionChoiceIndex}-title`
                    "
                    :name="
                      `question-${questionIndex}--choice-${questionChoiceIndex}-title`
                    "
                    :state="
                      invalid
                        ? validation.$each[questionIndex].question_choices
                            .$each[questionChoiceIndex].question_choice_title
                            .required
                        : null
                    "
                    v-model="
                      questions[questionIndex].question_choices[
                        questionChoiceIndex
                      ].question_choice_title
                    "
                  >
                  </b-form-input>

                  <a href="javascript:void(0)" class="ml-3">
                    <font-awesome-icon
                      icon="window-close"
                      @click="removeChoice(questionIndex, questionChoiceIndex)"
                    ></font-awesome-icon>
                  </a>

                  <b-form-invalid-feedback
                    :id="
                      `question-${questionIndex}--choice-${questionChoiceIndex}-title-feedback`
                    "
                  >
                    กรุณากรอกตัวเลือก
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-form-row>
          </template>

          <b-form-row>
            <b-col>
              <b-button
                variant="primary"
                size="sm"
                class="mt-3"
                @click="addChoice(questionIndex)"
                >เพิ่มคำตอบ</b-button
              >
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row> -->
    </template>
  </b-col>
</template>

<script>
import TestQuestionItem from "./TestQuestionItem";

export default {
  props: {
    vModel: {
      type: Array,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  components: {
    TestQuestionItem,
  },

  computed: {
    questions: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },
  },

  methods: {
    addTopic() {
      this.questions.push({
        // survey_topic_no: nextSurveyTopicNo,
        question_type: "topic",
        survey_topic_title: "",
        questions: [],
      });

      this.$nextTick(() => {
        setTimeout(() => {
          const nextTopicCard = this.$refs[
            `survey-topic-${this.questions.length - 1}`
          ];

          if (nextTopicCard && nextTopicCard.length) {
            nextTopicCard[0].scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }, 200);
      });
    },

    removeTopic(topicIndex) {
      this.questions.splice(topicIndex, 1);
    },

    generateQuestionData(type) {
      let formQuestion = {
        // question_no,
        question_type: type,
        question_title: "",
      };

      if (type === "choice") {
        this.$set(formQuestion, "question_choices", []);
      }

      return formQuestion;
    },

    addGeneralQuestion(type) {
      this.questions.push(this.generateQuestionData(type));

      this.$nextTick(() => {
        setTimeout(() => {
          const nextQuestionCard = this.$refs[
            `question-${this.questions.length - 1}`
          ];

          if (nextQuestionCard && nextQuestionCard.length) {
            nextQuestionCard[0].scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }, 200);
      });
    },

    addTopicQuestion(type, index) {
      let formQuestion = this.generateQuestionData(
        // this.topic_quetions[surveyTopicNo].length + 1,
        type
      );

      // this.$set(formQuestion, "survey_topic_no", surveyTopicNo);

      this.questions[index].questions.push(formQuestion);

      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     const nextQuestionCard = this.$refs[
      //       `topicQuestion-${index}--${this.questions[index].questions.length -
      //         1}`
      //     ];

      //     if (nextQuestionCard && nextQuestionCard.length) {
      //       nextQuestionCard[0].$el.scrollIntoView({
      //         behavior: "smooth",
      //         block: "end",
      //         inline: "nearest",
      //       });
      //     }
      //   }, 200);
      // });
    },

    removeQuestion(questionIndex) {
      this.questions.splice(questionIndex, 1);
    },

    removeTopicQuestion(questionIndex, questionTopicIndex) {
      this.questions[questionIndex].questions.splice(questionTopicIndex, 1);
    },
  },
};
</script>
