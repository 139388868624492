<template>
  <b-modal
    id="create-or-update-survey-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไข ข้อสอบ/แบบทดสอบ</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <test-form
      v-model="form"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></test-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import TestForm from "../form/TestForm";
import { Auth } from "../../models";
import axios from "axios";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    TestForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
    };
  },

  validations: {
    form: {
      survey_title: { required },
      survey_type: { required },
      // survey_topics: {
      //   required,
      //   minLength: minLength(1),
      //   $each: {
      //     survey_topic_title: {
      //       required,
      //     },
      //     questions: {
      //       required,
      //       minLength: minLength(1),
      //       $each: {
      //         question_title: {
      //           required,
      //         },
      //         question_type: {
      //           required,
      //         },
      //         question_choices: {
      //           $each: {
      //             question_choice_title: {
      //               required,
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },

      questions: {
        required,
        minLength: minLength(1),
        $each: {
          question_title: {
            required: requiredIf(
              (question) => question.question_type !== "topic"
            ),
          },
          question_type: {
            required,
          },
          question_choices: {
            required: requiredIf(
              (question) => question.question_type === "choice"
            ),
            $each: {
              question_choice_title: {
                required,
              },
            },
          },
          survey_topic_title: {
            required: requiredIf(
              (question) => question.question_type === "topic"
            ),
          },
          questions: {
            required: requiredIf(
              (question) => question.question_type === "topic"
            ),
            $each: {
              question_title: {
                required,
              },
              question_type: {
                required,
              },
              question_choices: {
                required: requiredIf(
                  (question) => question.question_type === "choice"
                ),
                $each: {
                  question_choice_title: {
                    required,
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      // if (this.$v.$invalid) {
      //   this.invalid = true;
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      let formData = {
        ...this.form,
      };

      formData = this.generateNumber(formData);

      formData = this.formatData(formData);

      this.isSubmiting = true;

      // let promise;
      

      try {
        console.log(formData)
        // if (this.editData && !this.editData.id) {
        //   promise = await Survey.api().create(formData);
        // } else {
        //   promise = await Survey.api().update(formData.id, formData);
        // }

        var savedData = await axios.post(
        `https://e-learnning-service-temp.yuzudigital.com/tests/form`,
        formData
      );
      console.log(savedData.data);

        const { data } = savedData;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-survey-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${
              data.response_description
            } กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    generateQuestionNumber(question, questionIndex, surveyTopicNo) {
      const question_no = questionIndex + 1;
      let additionalData = {};
      let { question_type, question_title, question_choices = [] } = question;

      if (question_choices && question_choices.length) {
        question_choices = question_choices.map(
          (questionChoice, questionChoiceIndex) => {
            if (questionChoice.id) {
              this.$set(questionChoice, "id", questionChoice.id);
            }

            this.$set(
              questionChoice,
              "question_choice_no",
              questionChoiceIndex + 1
            );

            return questionChoice;
          }
        );

        this.$set(additionalData, "question_choices", question_choices);
      }

      if (surveyTopicNo) {
        this.$set(additionalData, "survey_topic_no", surveyTopicNo);
      }

      const questionData = {
        question_no,
        question_type,
        question_title,
        ...additionalData,
      };

      if (question.id) {
        this.$set(questionData, "id", question.id);
      }

      return questionData;
    },

    generateNumber(formData) {
      const survey_topics = [];

      let merged_questions = [];

      const { questions = [] } = formData;

      questions.forEach((question, questionIndex) => {
        if (question.question_type === "topic") {
          const surveyTopicNo = questionIndex + 1;

          const surveyTopicData = {
            survey_topic_no: surveyTopicNo,
            survey_topic_title: question.survey_topic_title,
          };

          if (question.id) {
            this.$set(surveyTopicData, "id", question.id);
          }

          survey_topics.push(surveyTopicData);

          let { questions: topicQuestions = [] } = question;

          topicQuestions = topicQuestions.map(
            (topicQuestion, topicQuestionIndex) => {
              return this.generateQuestionNumber(
                topicQuestion,
                topicQuestionIndex,
                surveyTopicNo
              );
            }
          );

          merged_questions = [...merged_questions, ...topicQuestions];
        } else {
          merged_questions.push(
            this.generateQuestionNumber(question, questionIndex)
          );
        }
      });

      // survey_topics = survey_topics.map((surveyTopic, surveyTopicIndex) => {
      //   const surveyTopicNo = surveyTopicIndex + 1;
      //   let { questions = [] } = surveyTopic;

      //   this.$set(surveyTopic, "questions", questions);

      //   this.$set(surveyTopic, "survey_topic_no", surveyTopicNo);

      //   return surveyTopic;
      // });

      // survey_topics = survey_topics.map((surveyTopic, surveyTopicIndex) => {
      //   const surveyTopicNo = surveyTopicIndex + 1;
      //   let { questions = [] } = surveyTopic;

      //   questions = questions.map((question, questionIndex) => {
      //     const questionNo = questionIndex + 1;
      //     let { question_choices = [] } = question;

      //     if (question_choices && question_choices.length) {
      //       question_choices = question_choices.map(
      //         (questionChoice, questionChoiceIndex) => {
      //           this.$set(
      //             questionChoice,
      //             "question_choice_no",
      //             questionChoiceIndex + 1
      //           );

      //           return questionChoice;
      //         }
      //       );

      //       this.$set(question, "question_choices", question_choices);
      //     }

      //     this.$set(question, "question_no", questionNo);
      //     this.$set(question, "survey_topic_no", surveyTopicNo);

      //     return question;
      //   });

      //   this.$set(surveyTopic, "questions", questions);

      //   this.$set(surveyTopic, "survey_topic_no", surveyTopicNo);

      //   return surveyTopic;
      // });

      this.$set(formData, "survey_topics", survey_topics);
      this.$set(formData, "questions", merged_questions);

      return formData;
    },

    formatData(formData) {
      let { survey_topics = [], ...otherData } = formData;

      let questions = [];

      survey_topics = survey_topics.map((surveyTopic) => {
        const {
          questions: surveyTopicQuestions = [],
          ...otherSurveyTopicData
        } = surveyTopic;

        questions = [...questions, ...surveyTopicQuestions];

        return otherSurveyTopicData;
      });

      return {
        survey_topics,
        questions,
        ...otherData,
      };
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;

      this.form = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
